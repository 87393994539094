<template>
  <div>
    <div class="utils__title mb-3">
      <strong class="text-uppercase font-size-16">会社グループタイマー更新</strong>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <form-company-group-timer
              :submitType="submitType"
              :submitString="submitString"
            />
            <p class="text-right">
              <a-button type="danger" @click="deleteTimer">削除</a-button>
            </p>
          </div>
        </div>
      </div>
    </div>
    <a-modal
      :visible="deleteTimerModalVisible"
      :footer="null"
      @cancel="deleteTimerModalVisible = false"
    >
      <p>このタイマーを削除してもよろしいですか？</p>
      <a-button type="danger" @click="deleteTimer">削除</a-button>
    </a-modal>
  </div>
</template>

<script>
import Vue from 'vue'
import FormCompanyGroupTimer from '../../../components/FormComponents/CompanyGroupTimer'

export default {
  components: {
    FormCompanyGroupTimer,
  },
  data() {
    return {
      deleteTimerModalVisible: false,
    }
  },
  props: {
    submitType: {
      type: String,
      default: 'put',
    },
    submitString: {
      type: String,
      default: '更新',
    },
  },
  created() {
  },
  methods: {
    deleteTimer() {
      this.deleteTimerModalVisible = true
    },
    deleteTimerOk() {
      Vue.prototype.$api.send('delete', 'company_groups/timers/' + this.$route.params.id)
      this.deleteTimerModalVisible = false
    },
  },
}
</script>

<style>
.list-name {
  font-size: 1.5rem;
}
</style>
